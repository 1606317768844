export const translation = {
    data() {
        return {
            title: ["Useful documents", "Dokumenty do pobrania"],
            text: ["We provide documents that may be useful during:", "Udostępniamy Państwo dokumenty, którą mogą się przydać podczas:"],
            sell: ["vehicle sales,", "sprzedaży pojazdu,"],
            give: ["vehicle donations,", "darowizny pojazdu,"],
            quit: ["terminate motor liability.", "wypowiedzania OC komunikacyjnego."],
            info: ["* All documents are in PDF format.", "* Wszystkie dokumenty są w formacie PDF."],
            sellFile: ["Car purchase and sale agreement", "Umowa kupna/sprzedaży samochodu"],
            giveFile: ["Vehicle donation agreement", "Umowa darowizny pojazdu"],
            quitFile: ["Termination of motor liability", "Wypowiedzenie OC komunikacyjnego"]
        }
    },
    methods: {
        getTitle() {
            return this.title[this.$store.state.language];
        },
        getText() {
            return this.text[this.$store.state.language];
        },
        getSell() {
            return this.sell[this.$store.state.language];
        },
        getGive() {
            return this.give[this.$store.state.language];
        },
        getQuit() {
            return this.quit[this.$store.state.language];
        },
        getInfo() {
            return this.info[this.$store.state.language];
        },
        getSellFile() {
            return this.sellFile[this.$store.state.language];
        },
        getGiveFile() {
            return this.giveFile[this.$store.state.language];
        },
        getQuitFile() {
            return this.quitFile[this.$store.state.language];
        }
    }
}