<template>
  <header class="header">
    <Logo />
    <Title />
    <Phone />
    <Settings />
  </header>
</template>
<script>
import Logo from "./components/Logo";
import Title from "./components/Title";
import Phone from "./components/Phone";
import Settings from "./components/Settings";
export default {
  components: {
    Logo,
    Title,
    Phone,
    Settings
  }
};
</script>
<style>
.header {
  border-bottom: 1px solid #ebebeb;
  color: #282727;
  display: grid;
  grid-area: 1/2;
  grid-template-columns: 200px auto auto auto;
  grid-template-rows: 70px;
  padding: 16px 0 16px 0;
}
@media only screen and (max-width: 992px) {
  .header {
    grid-template-columns: 156px auto auto auto;
    grid-template-rows: 56px;
  }
}
@media only screen and (max-width: 768px) {
  .header {
    display: none;
  }
}
</style>