<template>
  <router-link class="home-offert" tag="a" :to="to">
    <font-awesome-icon :icon="icon" />
    <p>{{main}}</p>
    <p v-if="sub">{{sub}}</p>
  </router-link>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: {
    icon: {
      type: String,
      required: true
    },
    main: {
      type: String,
      required: true
    },
    sub: {
      type: String,
      required: false
    },
    to: {
      type: String,
      required: true
    }
  }
};
</script>
<style>
.home-offert {
  background-color: #f9f9f9;
  border: 1px solid #a3bad4;
  color: #7698be;
  font-size: 40px;
  height: 100px;
  padding-top: 20px;
  position: relative;
  text-align: center;
}
.home-offert:hover {
  background-color: #ffffff;
  border-color: #7698be;
  color: #1b5494;
  cursor: pointer;
}
.home-offert:hover > p {
  color: #1b5494;
}
.home-offert > p {
  bottom: 0px;
  color: #686767;
  font-size: 18px;
  padding-bottom: 10px;
  position: absolute;
  width: 100%;
}
@media only screen and (max-width: 1312px) {
  .home-offert {
    font-size: 32px;
    height: 88px;
    padding-top: 12px;
  }
  .home-offert > p {
    font-size: 14px;
  }
}
@media only screen and (max-width: 1024px) {
}
</style>