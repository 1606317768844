<template>
  <div class="header-settings">
    <a
      href="https://www.facebook.com/Warmia-Biuro-Ubezpiecze%C5%84-104271554574006/"
      target="_blank"
      rel="nofollow"
      class="header-settings-facebook"
    >Facebook</a>
    <button @click="setPolishLanguage()" class="change-language">PL</button> |
    <button @click="setEnglishLanguage()" class="change-language">EN</button>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  methods: {
    ...mapActions(["setPolishLanguage", "setEnglishLanguage"])
  }
};
</script>
<style>
.header-settings {
  color: #1b5494;
  text-align: right;
}
.header-settings-facebook {
  color: #7698be;
  padding-right: 16px;
}
.header-settings-facebook:hover {
  color: #1b5494;
}
.change-language {
  color: #bebebe;
  cursor: pointer;
  background-color: #ffffff;
  border: none;
}
.change-language:hover {
  color: #1b5494;
}
</style>