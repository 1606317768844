<template>
  <ul class="list-style-none">
    <li>{{this.getMonFri()}}{{this.getOpen()}}</li>
    <li>{{this.getSat()}}{{this.getOpenWeekend()}}</li>
  </ul>
</template>
<script>
import { mapGetters } from "vuex";
import { translation } from "../translation";
export default {
  mixins: [translation],
  methods: {
    ...mapGetters(["getOpen", "getOpenWeekend"])
  }
};
</script>