<template>
  <div class="mobile-navigation-drawer-header">
    <router-link to="/" active-class="none" exact>
      <img
        src="../../../public/images/icon.png"
        alt="Warmia Ikona"
        title="Warmia Biuro Ubezpieczeń ikona"
        class="mobile-navigation-drawer-header-icon"
      />
    </router-link>
    <h1>Warmia - {{this.getTitle()}}</h1>
    <h2>{{this.getSubtitle()}}</h2>
  </div>
</template>
<script>
import { translation } from "../header/translation";
export default {
  mixins: [translation]
};
</script>
<style>
.mobile-navigation-drawer-header {
  padding-top: 16px;
  font-family: "Roboto", sans-serif;
  color: #282727;
  border-bottom: 1px solid #ebebeb;
}
.mobile-navigation-drawer-header > h1 {
  font-weight: normal;
  font-size: 16px;
  padding-bottom: 4px;
}
.mobile-navigation-drawer-header > h2 {
  color: #868585;
  font-weight: normal;
  font-size: 14px;
}
.mobile-navigation-drawer-header-icon {
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 32px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  height: 32px;
  margin-bottom: 16px;
  padding: 8px;
  width: 32px;
  transition-duration: 1s;
}
.mobile-navigation-drawer-header-icon:active {
  box-shadow: none;
}
</style>