<template>
  <ul class="insurance-types list-style-none">
    <router-link tag="li" to="/ubezpieczenia/komunikacyjne">{{this.getVehicle()}}</router-link>
    <router-link tag="li" to="/ubezpieczenia/zdrowotne">{{this.getHealth()}}</router-link>
    <router-link tag="li" to="/ubezpieczenia/mienia">{{this.getProperty()}}</router-link>
    <router-link tag="li" to="/ubezpieczenia/firmowe">{{this.getBusiness()}}</router-link>
    <router-link tag="li" to="/ubezpieczenia/turystyczne">{{this.getTravel()}}</router-link>
    <router-link tag="li" to="/ubezpieczenia/rolne">{{this.getAgro()}}</router-link>
    <router-link tag="li" to="/ubezpieczenia/zycie">{{this.getLife()}}</router-link>
    <router-link tag="li" to="/ubezpieczenia/grupowe">{{this.getGroup()}}</router-link>
  </ul>
</template>
<script>
import { translation } from "../../section/home/translation";
export default {
  mixins: [translation]
};
</script>
<style>
.insurance-types > li {
  cursor: pointer;
  width: max-content;
}
.insurance-types > li:hover {
  color: #7698be;
}
</style>