<template>
  <div class="home-offers">
    <Offer icon="car-alt" :main="this.getVehicle()" :to="offersURL" />
    <Offer icon="heartbeat" :main="this.getHealth()" :to="offersURL" />
    <Offer icon="home" :main="this.getProperty()" :to="offersURL" />
    <Offer icon="briefcase" :main="this.getBusiness()" :to="offersURL" />
    <Offer icon="umbrella-beach" :main="this.getTravel()" :to="offersURL" />
    <Offer icon="tractor" :main="this.getAgro()" :to="offersURL" />
    <Offer icon="hand-holding-heart" :main="this.getLife()" :to="offersURL" />
    <Offer icon="users" :main="this.getGroup()" :to="offersURL" />
  </div>
</template>
<script>
import Offer from "./Offer";
import { translation } from "../translation";
export default {
  data() {
    return {
      offersURL: "/ubezpieczenia"
    };
  },
  components: {
    Offer
  },
  mixins: [translation]
};
</script>
<style>
.home-offers {
  column-gap: 32px;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
}
@media only screen and (max-width: 1312px) {
  .home-offers {
    column-gap: 16px;
  }
}
@media only screen and (max-width: 864px) {
  .home-offers {
    column-gap: 16px;
    row-gap: 16px;
    grid-template-columns: repeat(4, 1fr);
  }
}
</style>