<template>
  <section class="partners" title="Nasi Partnerzy">
    <Partner partner="allianz.png" />
    <Partner partner="aviva.png" />
    <Partner partner="balcia.png" />
    <Partner partner="compensa.png" />
    <Partner partner="euroins.png" />
    <Partner partner="generali-agro.png" />
    <Partner partner="hdi.png" />
    <Partner partner="hestia.png" />
    <Partner partner="inter-risk.png" />
    <Partner partner="link4.png" />
    <Partner partner="lloyds.png" />
    <Partner partner="metlife.png" />
    <Partner partner="mtu.png" />
    <Partner partner="polisa-zycie.png" />
    <Partner partner="proama.png" />
    <Partner partner="pzu.png" />
    <Partner partner="saltus.png" />
    <Partner partner="signal-iduna.png" />
    <Partner partner="tuwtuz.png" />
    <Partner partner="uniqa.png" />
    <Partner partner="warta.png" />
    <Partner partner="wiener.png" />
  </section>
</template>
<script>
import Partner from "./Partner";
export default {
  components: {
    Partner
  },
  data() {
    return {
      partners: null,
      elementWidht: null,
      carouselWidth: null,
      carouselTimer: null
    };
  },
  methods: {
    setElementInRow() {
      let wholeWidth = 0;
      for (let i = 1; i < this.partners.length; i++) {
        wholeWidth += this.partners[i - 1].offsetWidth;
        this.partners[i].style.left = wholeWidth + "px";
      }
      this.elementWidht = this.partners[0].offsetWidth;
      this.carouselWidth = wholeWidth;
    },
    moveCarousel() {
      clearInterval(this.carouselTimer);
      this.carouselTimer = this.startInterval();
    },
    startInterval() {
      return setInterval(() => {
        let minOffsetLeft = -1 * this.elementWidht;
        for (let i = 0; i < this.partners.length; i++) {
          if (this.partners[i].offsetLeft <= minOffsetLeft) {
            this.partners[i].style.left = this.carouselWidth + "px";
          }
          this.partners[i].style.left = this.partners[i].offsetLeft - 1 + "px";
        }
      }, 50);
    }
  },
  mounted() {
    this.partners = document.getElementsByClassName("partner");
    this.setElementInRow();
    this.moveCarousel();
  },
  beforeDestroy() {
    clearInterval(this.carouselTimer);
  }
};
</script>
<style>
.partners {
  height: 28px;
  overflow: hidden;
  padding: 8px 0 8px 0;
  position: relative;
  width: 100%;
}
@media only screen and (min-width: 768px) {
}
@media only screen and (min-width: 1200px) {
  .partners {
    margin-top: 0;
  }
}
</style>