<template>
  <nav class="nav">
    <router-link to="/" exact>{{this.getHome()}}</router-link>
    <router-link to="/ubezpieczenia">{{this.getOffers()}}</router-link>
    <router-link to="/informacje">{{this.getAbout()}}</router-link>
    <router-link to="/dokumenty">{{this.getDocuments()}}</router-link>
    <router-link to="/kontakt">{{this.getContact()}}</router-link>
  </nav>
</template>
<script>
import { translation } from "./translation";
export default {
  mixins: [translation]
};
</script>
<style>
.nav {
  column-gap: 20px;
  display: grid;
  grid-area: 2/2;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: auto;
}
.nav > a {
  color: #686767;
  cursor: pointer;
  font-size: 18px;
  padding: 10px 0;
  text-decoration: none;
  text-align: center;
}
.nav > a:hover {
  background-color: #ebebeb;
}
@media only screen and (max-width: 992px) {
  .nav {
    column-gap: 0px;
  }
  .nav > a {
    font-size: 16px;
  }
}
@media only screen and (max-width: 768px) {
  .nav {
    display: none;
  }
}
</style>