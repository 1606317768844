export const translation = {
    data() {
        return {
            title: ["Insurance Office", "Biuro Ubezpieczeń"],
            subtitle: ["We insure effectively", "Ubezpieczamy skutecznie"]
        }
    },
    methods: {
        getTitle() {
            return this.title[this.$store.state.language];
        },
        getSubtitle() {
            return this.subtitle[this.$store.state.language];
        }
    }
}