<template>
  <section class="contact">
    <div class="contact-data">
      <div>
        <h1>
          <font-awesome-icon icon="phone-square-alt" />
          {{this.getContactTitle()}}
        </h1>
        <ContactList />
      </div>
      <div>
        <h1>
          <font-awesome-icon icon="map-marker-alt" />
          {{this.getLocalization()}}
        </h1>
        <Localization />
      </div>
      <div>
        <h1>
          <font-awesome-icon icon="clock" />
          {{this.getWorkingTitle()}}
        </h1>
        <WorkHours />
      </div>
      <div></div>
    </div>
    <iframe frameborder="0" style="border:none" :src="googleMapSrc" allowfullscreen></iframe>
  </section>
</template>
<script>
import { mapGetters } from "vuex";
import ContactList from "../../footer/components/ContactList";
import Localization from "../../footer/components/LocalizationInfo";
import WorkHours from "../../footer/components/WorkHours";
import { translation } from "../../footer/translation";
export default {
  data() {
    return {
      googleMapSrc: ""
    };
  },
  components: {
    ContactList,
    Localization,
    WorkHours
  },
  methods: {
    ...mapGetters(["getGoogleMapUrl"])
  },
  mounted() {
    this.googleMapSrc = this.getGoogleMapUrl();
  },
  mixins: [translation]
};
</script>
<style>
.contact {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.contact > iframe {
  min-width: 100%;
  min-height: 512px;
}
.contact-data {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.contact-data > div > h1 {
  color: #1b5494;
}
@media only screen and (max-width: 768px) {
  .contact-data {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
  }
  .contact-data > div > h1 {
    font-size: 22px;
  }
}
@media only screen and (max-width: 640px) {
  .contact {
    grid-template-columns: 1fr;
    grid-template-rows: auto 368;
  }
}
</style>