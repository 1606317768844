import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../components/section/home/Home';
import Offers from '../components/section/offers/Offers';
import About from '../components/section/about/About';
import Documents from '../components/section/documents/Documents';
import Contact from '../components/section/contact/Contact';
Vue.use(VueRouter)
const routes = [
  {
    path: '',
    component: Home
  },
  {
    path: '/ubezpieczenia',
    component: Offers
  },
  {
    path: '/informacje',
    component: About
  },
  {
    path: '/dokumenty',
    component: Documents
  },
  {
    path: '/kontakt',
    component: Contact
  },
  {
    path: '/',
    redirect: ''
  }
]

const router = new VueRouter({
  mode: 'history',
  linkActiveClass: 'active',
  routes,
})

export default router
