<template>
    <div class="offer">
            <h3><font-awesome-icon :icon="icon" /> {{title}}</h3>
            <p :v-if="subtext">{{subtext}}</p>
    </div>
</template>
<script>
export default {
    props: {
        title: {
            type: String,
            required: true
        },
        icon: {
            type: String,
            required: true
        },
        subtext: {
            type: String,
            required: false,
            default: null
        }
    }
}
</script>
<style>
.offer{
    padding: 8px 0 8px 0;
}
.offer > h3 {
    color: #1b5494;
}
.offer > p{
    margin: 0 0 0 64px;
}
</style>