<template>
  <section class="offers">
    <h1>{{ this.getSectionTitle() }}</h1>
    <div class="offers_grid">
      <div>
        <Offer
          :title="this.getVehicle()"
          icon="car-alt"
          subtext="OC, AC, flotowe"
        />
        <Offer :title="this.getHealth()" icon="heartbeat" />
        <Offer :title="this.getProperty()" icon="home" />
        <Offer :title="this.getBusiness()" icon="briefcase" />
        <Offer :title="this.getTravel()" icon="umbrella-beach" />
        <Offer :title="this.getAgro()" icon="tractor" />
        <Offer :title="this.getLife()" icon="hand-holding-heart" />
        <Offer :title="this.getGroup()" icon="users" />
        <Offer :title="this.getSchool()" icon="user-graduate" />
      </div>
      <div>
        <h3>{{ this.getReferenceLinks() }}:</h3>
        <OfferLink
          :title="this.getTravelReference()"
          :url="travelURL"
          icon="umbrella-beach"
        />
        <OfferLink
          :title="this.getSchoolReference()"
          :url="schoolURL"
          icon="user-graduate"
        />
      </div>
    </div>
  </section>
</template>
<script>
import Offer from "./Offer";
import OfferLink from "./OfferLink";
import { translation } from "./translation.js";
export default {
  data() {
    return {
      travelURL:
        "https://turystyka.compensa.pl/#VoyageInsurance/TripInformation?guid=d3a71bf5-bbd9-4516-ae69-ff24c6ea6e08",
      schoolURL:
        "http://nnwszkolne.compensa.pl/#NNWInsurance/StartScreen?guid=b8b7c6cd-125c-42eb-acfb-f0fcf945b38f",
    };
  },
  components: {
    Offer,
    OfferLink,
  },
  mixins: [translation],
};
</script>
<style>
.offers_grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
@media only screen and (max-width: 864px) {
  .offers_grid {
    grid-template-columns: 1fr;
  }
}
</style>