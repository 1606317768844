<template>
  <div id="app">
    <Header />
    <Navigation />
    <MobileHeader />
    <router-view></router-view>
    <Footer />
    <cookie-law :buttonText="accept" :message="cookieMessage"></cookie-law>
  </div>
</template>
<script>
import Header from "./components/header/Header";
import Navigation from "./components/navigation/Navigation";
import MobileHeader from "./components/mobile/MobileHeader";
import Footer from "./components/footer/Footer";
import CookieLaw from "vue-cookie-law";
export default {
  data() {
    return {
      accept: "Akceptuje",
      cookieMessage:
        "Ta strona korzysta z plików cookie, aby zapewnić najlepszą jakość korzystania z naszej witryny.",
    };
  },
  components: {
    Header,
    Navigation,
    MobileHeader,
    Footer,
    CookieLaw,
  },
  beforeMount() {
    window.fbAsyncInit = function () {
      FB.init({
        xfbml: true,
        version: "v6.0",
      });
    };

    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/pl_PL/sdk/xfbml.customerchat.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  },
};
</script>
<style>
/* ===  BASIC COLORS  ===
 dark: #282727
    lighter: 	#686767
    2x lighter: #ebebeb
 blue: #1b5494
    lighter: #7698BE
    2x lighter: #A3BAD4
    light blue: #B4D2F2
 */
body {
  color: #282727;
  font-family: "Lato", sans-serif;
  margin: 0;
  padding: 0;
}
p {
  margin: 0;
  padding: 0;
}
* {
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
svg {
  -webkit-transition: none;
  -o-transition: none;
  transition: none;
}
a {
  text-decoration: none;
}
a[href^="tel"],
a[href^="mailto"] {
  color: inherit;
  text-decoration: none;
}
h1,
h2,
h3,
h4 {
  margin: 0;
  padding: 0;
}
#app {
  display: grid;
  grid-template-columns: auto 1280px auto;
  grid-template-rows: repeat(4, auto);
}
.active {
  background-color: #ebebeb;
}
.websiteinprogress {
  padding: 64px 0 64px 0;
  text-align: center;
}
.list-style-none {
  list-style-type: none;
}
section {
  grid-area: 3/2;
  padding: 64px 0 64px 0;
}
@media only screen and (max-width: 1312px) {
  #app {
    grid-template-columns: 16px auto 16px;
  }
  .footer {
    grid-template-columns: 16px auto 16px;
  }
  section {
    padding: 32px 0 32px 0;
  }
}
</style>