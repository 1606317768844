<template>
  <div class="home-contact">
    <div class="home-contact-info">
      <div>
        <h3>{{this.getOfficeLocation()}}</h3>
        <LocalizationInfo />
      </div>
      <div>
        <h3>{{this.getWorkHours()}}</h3>
        <WorkHours />
      </div>
      <div>
        <h3>{{this.getContact()}}</h3>
        <ContactList />
      </div>
      <div class="home-contact-info-facebook">
        <h3>Facebook:</h3>
        <div
          class="fb-page"
          :data-href="facebookSrc"
          data-width
          data-height="130px"
          data-small-header="false"
          data-adapt-container-width="true"
          data-hide-cover="false"
          data-show-facepile="false"
        ></div>
      </div>
    </div>
    <iframe
      width="100%"
      height="100%"
      frameborder="0"
      style="border:none"
      :src="googleMapSrc"
      allowfullscreen
    ></iframe>
  </div>
</template>
<script>
import { translation } from "../translation";
import { mapGetters } from "vuex";
import LocalizationInfo from "../../../footer/components/LocalizationInfo";
import WorkHours from "../../../footer/components/WorkHours";
import ContactList from "../../../footer/components/ContactList";
export default {
  data() {
    return {
      facebookSrc: "",
      googleMapSrc: ""
    };
  },
  mixins: [translation],
  components: {
    LocalizationInfo,
    WorkHours,
    ContactList
  },
  methods: {
    ...mapGetters(["getFacebookUrl", "getGoogleMapUrl"])
  },
  mounted() {
    this.facebookSrc = this.getFacebookUrl();
    this.googleMapSrc = this.getGoogleMapUrl();
  }
};
</script>
<style>
.home-contact {
  background-color: #b4d2f2;
  color: #ffffff;
  display: grid;
  grid-template-columns: 1fr 1fr;
  text-align: left;
}
.home-contact-info {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 32px;
}
.home-contact-info > div > ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.home-contact-info > div > h3,
li {
  padding-bottom: 16px;
}
@media only screen and (max-width: 992px) {
  .home-contact-info-facebook {
    grid-area: 3/1/3/3;
  }
  .home-contact-info {
    padding: 16px;
  }
  .home-contact-info > div > h3,
  li {
    padding-bottom: 10px;
  }
}
@media only screen and (max-width: 722px) {
  .home-contact {
    grid-template-columns: 1fr;
    grid-template-rows: auto 368px;
  }
  .home-contact-info-facebook{
    display: none;
  }
}
</style>