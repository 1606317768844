<template>
  <section class="about">
    <h1>Warmia - {{ this.getTitle() }}</h1>
    <p>{{ this.getP1() }}</p>
    <p>{{ this.getP2() }}</p>
    <p>{{ this.getP3() }}</p>
    <p>{{ this.getP4() }}</p>
    <ul class="list-style-none">
      <li>{{ this.getLi1() }}</li>
      <li>{{ this.getLi2() }}</li>
      <li>{{ this.getLi3() }}</li>
    </ul>
    <p>{{ this.getP5() }}</p>
    <p>
      Alianz, Aviva, Balcia, Compensa, Euroins, Generali, Generali Agro, HDI,
      Hestia, Inter-Risk, Link4, Lloyds, Metlife, MTU, Polisa-Życie,
      Proama, PZU, Saltus, Signal-Iduna, TUZ, Uniqua, Warta, Wiener.
    </p>
  </section>
</template>
<script>
import { translation } from "./translation.js";
export default {
  mixins: [translation],
};
</script>