export const translation = {
    data() {
        return {
            sectionTitle: ["Insurance Offers", "Oferty Ubezpieczeń"],
            vehicle: ["Vehicle", "Komunikacyjne"],
            health: ["Health", "Zdrowotne"],
            property: ["Property", "Mienia"],
            business: ["Business", "Firmowe"],
            travel: ["Travel", "Turystyczne"],
            agro: ["Agricultural", "Rolne"],
            life: ["Life", "Życie"],
            group: ["Group", "Grupowe"],
            school: ["School", "Szkolne"],
            referenceLinks: ["Reference insurance links:","Referencyjne linki do ubezpieczeń:"], 
            travelReference: ["Trave Insurance Compensa Voyage","Ubezpieczenie Turystyczne Compensa Voyage"],
            schoolReference: ["Compensa Child School Insurance","Ubezpieczenie Szkolne NNW Compensa"]
        }
    },
    methods: {
        getSectionTitle(){
            return this.sectionTitle[this.$store.state.language];
        },
        getVehicle() {
            return this.vehicle[this.$store.state.language];
        },
        getHealth() {
            return this.health[this.$store.state.language];
        },
        getProperty() {
            return this.property[this.$store.state.language];
        },
        getBusiness() {
            return this.business[this.$store.state.language];
        },
        getTravel() {
            return this.travel[this.$store.state.language];
        },
        getAgro() {
            return this.agro[this.$store.state.language];
        },
        getLife() {
            return this.life[this.$store.state.language];
        },
        getGroup() {
            return this.group[this.$store.state.language];
        },
        getSchool(){
            return this.school[this.$store.state.language];
        },
        getReferenceLinks(){
            return this.referenceLinks[this.$store.state.language];
        },
        getTravelReference(){
            return this.travelReference[this.$store.state.language];
        },
        getSchoolReference(){
            return this.schoolReference[this.$store.state.language];
        }
    }
}