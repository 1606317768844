import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { store } from './store/store';
import './registerServiceWorker'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCarAlt, faHeartbeat, faHome, faTractor, faUmbrellaBeach, faBriefcase, faHandHoldingHeart, faUsers, faViruses, faEnvelopeOpenText, faComments, faHandshake, faFileContract, faSmileBeam, faPhoneAlt, faTools, faFilePdf, faPhoneSquareAlt, faMapMarkerAlt, faClock, faAt, faBars, faUserGraduate, faThumbsUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faCarAlt, faHeartbeat, faHome, faBriefcase, faUmbrellaBeach, faTractor, faHandHoldingHeart, faUsers, faViruses, faEnvelopeOpenText, faComments, faHandshake, faFileContract, faSmileBeam, faPhoneAlt, faTools, faFilePdf, faPhoneSquareAlt, faMapMarkerAlt, faClock, faAt, faBars, faUserGraduate, faThumbsUp)
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')
