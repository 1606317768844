<template>
  <ul class="navigation-list list-style-none">
    <router-link to="/" tag="li" exact>{{this.getHome()}}</router-link>
    <router-link to="/ubezpieczenia" tag="li">{{this.getOffers()}}</router-link>
    <router-link to="/informacje" tag="li">{{this.getAbout()}}</router-link>
    <router-link to="/dokumenty" tag="li">{{this.getDocuments()}}</router-link>
    <router-link to="/kontakt" tag="li">{{this.getContact()}}</router-link>
  </ul>
</template>
<script>
import { translation } from "../../navigation/translation";
export default {
  mixins: [translation]
};
</script>
<style>
.navigation-list > li {
  cursor: pointer;
  width: max-content;
}
.navigation-list > li:hover{
  color: #7698BE;
}
</style>