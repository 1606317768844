<template>
  <div class="home-section">
    <h1>{{ this.getRecommend() }} <font-awesome-icon icon="thumbs-up" /></h1>
    <div class="recomendation">
      <RecomendedElement
        :title="getTravel()"
        :text="getRecommendTravel()"
        :url="travelURL"
        icon="umbrella-beach"
      />
      <RecomendedElement
        :title="getSchool()"
        :text="getRecommendSchool()"
        :url="schoolURL"
        icon="user-graduate"
      />
    </div>
    <p>{{this.getInvitation()}}</p>
    <p>{{this.getUseReferenceLinks()}}</p>
  </div>
</template>
<script>
import { translation } from "../../translation";
import RecomendedElement from "./RecomendedElement.vue";
export default {
  data() {
    return {
      travelURL:
        "https://turystyka.compensa.pl/#VoyageInsurance/TripInformation?guid=d3a71bf5-bbd9-4516-ae69-ff24c6ea6e08",
      schoolURL:
        "http://nnwszkolne.compensa.pl/#NNWInsurance/StartScreen?guid=b8b7c6cd-125c-42eb-acfb-f0fcf945b38f",
    };
  },
  mixins: [translation],
  components: {
    RecomendedElement,
  },
};
</script>
<style>
.home-section > h1 {
  background-color: deepskyblue;
  border-radius: 16px;
  color: white;
  letter-spacing: 6px;
  margin: 16px auto;
  padding: 16px 32px !important;
  width: max-content;
}
.recomendation {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 64px;
}
@media only screen and (max-width: 864px) {
  .home-section > h1 {
    font-size: 28px;
    letter-spacing: 4px;
    margin: 8px auto;
    padding: 8px 16px !important;
  }
  .recomendation {
    grid-template-columns: 1fr;
  }
}
</style>
