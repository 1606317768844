<template>
  <div class="header-title">
    <h1>{{this.getTitle()}}</h1>
    <h2>{{this.getSubtitle()}}</h2>
  </div>
</template>
<script>
import { translation } from "../translation";
export default {
  mixins: [translation]
};
</script>
<style>
.header-title {
  margin: auto 0 0 16px;
}
.header-title > h2 {
  color: #1b5494;
}
@media only screen and (max-width: 992px) {
  .header-title > h1 {
    font-size: 24px;
  }
  .header-title > h2 {
    font-size: 20px;
  }
}
</style>