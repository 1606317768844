export const translation = {
    data() {
        return {
            home: ["Home Page", "Strona Główna"],
            offers: ["Insurance Offers", "Oferty Ubezpieczeń"],
            about: ["About Us", "O Firmie"],
            documents: ["Documents", "Do Pobrania"],
            contact: ["Contact", "Kontakt"]
        }
    },
    methods: {
        getHome() {
            return this.home[this.$store.state.language];
        },
        getOffers() {
            return this.offers[this.$store.state.language];
        },
        getAbout() {
            return this.about[this.$store.state.language];
        },
        getDocuments() {
            return this.documents[this.$store.state.language];
        },
        getContact() {
            return this.contact[this.$store.state.language];
        }
    }
}