<template>
  <a class="document-download-button" target="_blank" :href="'./docs/' + filePath" download>
    <font-awesome-icon icon="file-pdf" class="file-icon" />
    <h2>{{this.fileName}}</h2>
  </a>
</template>
<script>
export default {
  props: {
    fileName: {
      type: String,
      require: true
    },
    filePath: {
      type: String,
      require: false
    }
  }
};
</script>
<style>
.document-download-button {
  background-color: #ebebeb;
  border: 1px solid #a3bad4;
  color: inherit;
  font-family: inherit;
  padding: 16px;
  text-align: center;
}
.document-download-button:hover {
  background-color: #ffffff;
}
.document-download-button:active {
  background-color: #b4d2f2;
  border-color: #1b5494;
}
.file-icon {
  color: firebrick;
  font-size: 42px;
  padding: 16px;
}
@media only screen and (max-width: 769px) {
  .file-icon {
    font-size: 24px;
    padding: 12px;
  }
  .document-download-button > h2{
    font-size: 16px;
  }
}
</style>