<template>
  <a :href="url" target="_blank" rel="nofollow">
    <div class="offer-link">
      <p><font-awesome-icon :icon="icon" /> {{ title }}</p>
    </div>
  </a>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
  },
};
</script>
<style>
.offer-link {
  font-size: 20px;
  color: #fff;
  padding: 16px;
  background-color: deepskyblue;
  margin: 8px;
  border-radius: 16px;
  display: block;
  text-align: center;
}
</style>