export const translation = {
    data() {
        return {
            title: ["Insurance Office", "Biuro Ubezpieczeń"],
            p1: ["Established in 1997. We cooperate with almost all insurance companies.", "Istniejemy od roku 1997. Współpracujemy z prawie wszystkimi firmamiubezpieczeniowymi."],
            p2: ["Our trained and experienced advisers will answer every question related to insurance and prepare the most advantageous current offer.", "Nasi wyszkoleni i doświadczeni doradcy odpowiedzą na każde pytanie związane z ubezpieczeniami oraz przygotują najkorzystniejszą aktualną ofertę."],
            p3: ["We constantly monitor the insurance market for you to offer the best and cheapest solutions. Our priority is the satisfaction of each client.", "Stale monitorujemy dla Państwa rynek ubezpieczeń, aby zaproponować jak najkorzystniejsze i najtańsze rozwiązania. Naszym priorytetem jest zadowolenie każdego klienta."],
            p4: ["We guarantee:", "Gwarantujemy:"],
            li1: ["Individual care for each client.", "Indywidualne podejście do każdego klienta."],
            li2: ["The highest quality of services.", "Najwyższą jakość usług."],
            li3: ["Competitive prices.", "Konkurencyjne ceny."],
            p5: ["Our insurance partners:", "Wspólpracujemy z następującymi firmami ubezpieczeniowymi:"]
        }
    },
    methods: {
        getTitle(){
            return this.title[this.$store.state.language];
        },
        getP1(){
            return this.p1[this.$store.state.language];
        },
        getP2(){
            return this.p2[this.$store.state.language];
        },
        getP3(){
            return this.p3[this.$store.state.language];
        },
        getP4(){
            return this.p4[this.$store.state.language];
        },
        getLi1(){
            return this.li1[this.$store.state.language];
        },
        getLi2(){
            return this.li2[this.$store.state.language];
        },
        getLi3(){
            return this.li3[this.$store.state.language];
        },
        getP5(){
            return this.p5[this.$store.state.language];
        },
    }
}