<template>
  <section class="home">
    <div class="home-section">
      <h2>{{this.getOffer()}}</h2>
      <Offers />
    </div>
    <Recomendation />
    <Promo />
    <HomeContact />
    <Partners />
  </section>
</template>
<script>
import Offers from "./offers/Offers";
import Promo from "./informations/Promo";
import Recomendation from "./informations/recomendation/Recomendation";
import HomeContact from "./contact/HomeContact";
import Partners from "./partners/Partners";
import { translation } from "./translation";
export default {
  data() {
    return {};
  },
  components: {
    Offers,
    Promo,
    Recomendation,
    HomeContact,
    Partners
  },
  mixins: [translation]
};
</script>
<style>
.home {
  padding: 16px 0 0 0;
  text-align: center;
}
.home-section {
  border-bottom: 1px solid #ebebeb;
  padding: 16px 0 32px 0;
}
.home-section > h2 {
  padding-bottom: 32px;
}
@media only screen and (max-width: 864px) {
  .home {
    padding: 0px;
  }
  .home-section > h2 {
    padding-bottom: 16px;
  }
}
</style>