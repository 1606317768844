<template>
  <ul class="list-style-none">
    <li>
      <a :href="'tel:'+this.getPhone()">{{this.getPhone()}}</a>
    </li>
    <li>
      <a :href="'tel:'+this.getMobileOne()">{{this.getMobileOne()}}</a>
    </li>
    <li>
      <a :href="'tel:'+this.getMobileTwo()">{{this.getMobileTwo()}}</a>
    </li>
    <li>
      <a :href="'mailto:'+this.getMail()">{{this.getMail()}}</a>
    </li>
  </ul>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {};
  },
  methods: {
    ...mapGetters(["getPhone", "getMobileOne", "getMobileTwo", "getMail"])
  }
};
</script>