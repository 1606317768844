<template>
  <div class="home-section" style="color: #1b5494">
    <h2>{{this.getPromoTitle()}}</h2>
    <p>{{this.getPromoSubtitle()}}</p>
  </div>
</template>
<script>
import { translation } from "../translation";
export default {
  mixins: [translation]
};
</script>