<template>
  <nav class="mobile-navigation-drawer-nav">
    <router-link to="/" active-class="mobile-navigation-drawer-nav-active" exact>{{this.getHome()}}</router-link>
    <router-link
      to="/ubezpieczenia"
      active-class="mobile-navigation-drawer-nav-active"
    >{{this.getOffers()}}</router-link>
    <router-link
      to="/informacje"
      active-class="mobile-navigation-drawer-nav-active"
    >{{this.getAbout()}}</router-link>
    <router-link
      to="/dokumenty"
      active-class="mobile-navigation-drawer-nav-active"
    >{{this.getDocuments()}}</router-link>
    <router-link
      to="/kontakt"
      active-class="mobile-navigation-drawer-nav-active"
    >{{this.getContact()}}</router-link>
  </nav>
</template>
<script>
import { translation } from "../navigation/translation";
export default {
  mixins: [translation]
};
</script>
<style>
.mobile-navigation-drawer-nav {
  display: grid;
  grid-template-rows: repeat(5, 50px);
  row-gap: 8px;
  grid-template-columns: 1fr;
  margin-top: 16px;
}
.mobile-navigation-drawer-nav > a {
  border-radius: 8px;
  color: #868585;
  font-weight: bolder;
  font-size: 14px;
  margin: auto 0;
  padding: 16px 0px 16px 16px;
}
.mobile-navigation-drawer-nav-active {
  background-color: #e8edf4;
  color: #31659e!important;
}
</style>