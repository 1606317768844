<template>
  <footer class="footer">
    <div
      class="footer-container"
      :style="{'background-image':'url('+ require('../../../public/images/logo-grey.png' ) + ')'}"
    >
      <div class="footer-offers">
        <h3>{{this.getInsuranceTitle()}}</h3>
        <InsuranceTypesList />
      </div>
      <div class="footer-contact">
        <h3>{{this.getContactTitle()}}</h3>
        <ContactList />
      </div>
      <div class="footer-location">
        <h3>{{this.getWorkingTitle()}}</h3>
        <WorkHours />
        <LocalizationInfo />
      </div>
      <div class="footer-navigation">
        <h3>{{this.getNavigationTitle()}}</h3>
        <NavigationList />
      </div>
    </div>
  </footer>
</template>
<script>
import InsuranceTypesList from "./components/InsuranceTypesList";
import ContactList from "./components/ContactList";
import WorkHours from "./components/WorkHours";
import LocalizationInfo from "./components/LocalizationInfo";
import NavigationList from "./components/NavigationList";
import { translation } from "./translation";
export default {
  components: {
    InsuranceTypesList,
    ContactList,
    WorkHours,
    LocalizationInfo,
    NavigationList
  },
  mixins: [translation]
};
</script>
<style>
.footer {
  background-color: #ebebeb;
  color: #686767;
  display: grid;
  font-size: 18px;
  grid-area: 4/1/4/4;
  grid-template-columns: auto 1280px auto;
}
.footer-container {
  background: no-repeat;
  background-position-x: center;
  background-position-y: center;
  background-size: 512px;
  display: grid;
  grid-area: 1/2;
  grid-template-columns: repeat(4, 1fr);
  padding: 64px 0 64px 0;
}
.footer-container > div > h3 {
  color: #7698be;
  margin: 0;
  padding: 0 0 16px 0;
}
.footer-container > * > ul {
  margin: 0;
  padding: 0 0 0 32px;
}
.footer-container > * > ul > li {
  padding: 0 0 8px 0;
}
@media only screen and (max-width: 992px) {
  .footer {
    font-size: 16px;
  }
  .footer-container {
    padding: 32px 0 32px 0;
  }
  .footer-container > div > h3 {
    font-size: 18px;
    padding: 0 0 10px 0;
  }
  .footer-container > * > ul {
    padding: 0 0 0 22px;
  }
  .footer-container > * > ul > li {
    padding: 0 0 6px 0;
  }
}
@media only screen and (max-width: 768px) {
  .footer-offers,
  .footer-navigation {
    display: none;
  }
  .footer-container {
    grid-template-columns: repeat(2, 1fr);
  }
}
</style>