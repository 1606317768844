<template>
  <div class="recomendation-element_container">
    <h2>{{ title }}</h2>
    <h3>{{ text }}</h3>
    <a
      class="recomendation-element_link"
      :href="url"
      target="_blank"
      rel="nofollow"
      >Link! <font-awesome-icon :icon="icon"
    /></a>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
  },
};
</script>
<style>
.recomendation-element_container {
  padding: 16px 0 32px 0;
}
.recomendation-element_container > h2 {
  text-transform: uppercase;
}
.recomendation-element_link {
  font-size: 16px;
  color: #fff;
  padding: 16px;
  background-color: #7698be;
  display: inline-block;
  margin: 8px;
  border-radius: 16px;
}
@media only screen and (max-width: 864px) {
}
</style>
