<template>
  <div class="header-phone">
    <a :href="'tel:'+this.getPhone()">
      <font-awesome-icon icon="phone-alt" />
      {{this.getPhone()}}
    </a>
    <br />
    <a :href="'mailto:'+this.getMail()" class="header-phone-mail">
      <font-awesome-icon icon="at" />
      {{this.getMail()}}
    </a>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  methods: {
    ...mapGetters(["getPhone", "getMail"])
  }
};
</script>
<style>
.header-phone {
  color: #1b5494;
  font-size: 22px;
  font-weight: bold;
  margin: auto;
  text-align: center;
}
.header-phone-mail {
  font-size: 18px;
}
@media only screen and (max-width: 992px) {
  .header-phone {
    font-size: 18px;
  font-weight: bolder;
  }
  .header-phone-mail {
    font-size: 14px;
  }
}
</style>