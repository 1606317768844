<template>
  <div
    class="partner"
    :style="{'background-image':'url('+ require('../../../../../public/partners/' + partner) + ')'}"
  ></div>
</template>
<script>
export default {
  props: {
    partner: {
      type: String,
      required: true
    }
  }
};
</script>
<style>
.partner {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: block;
  float: left;
  height: 28px;
  padding-right: 16px;
  position: absolute;
  -webkit-transition: none;
  -o-transition: none;
  transition: none;
  width: 128px;
}
</style>