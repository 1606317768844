import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);
export const store = new Vuex.Store({
    state: {
        language: 1,
        phone: "89 535 11 38",
        mobileOne: "600 954 165",
        mobileTwo: "603 650 201",
        mail: "warmia@xl.wp.pl",
        place: "C.H. MANHATTAN",
        street: "Pl. Pułaskiego 7/3",
        entrance: "Wejście nr 3, od ul . Kościuszki",
        address: "Olsztyn 10-514",
        open: "9:00-17:00",
        openWeekend: "10:00-13:00",
        facebookUrl: "https://www.facebook.com/Warmia-Biuro-Ubezpiecze%C5%84-104271554574006/",
        googleMapUrl: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d589.3794824928586!2d20.49018082926999!3d53.78026879119122!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46e2792d512f9441%3A0x6d1d4f7983a45298!2sWarmia.%20Biuro%20ubezpiecze%C5%84.%20Ubezpieczenia%20komunikacyjne%2C%20maj%C4%85tkowe%2C%20na%20%C5%BCycie!5e0!3m2!1spl!2spl!4v1573233588886!5m2!1spl!2spl"
    },
    getters: {
        getPhone: state => {
            return state.phone;
        },
        getMobileOne: state => {
            return state.mobileOne;
        },
        getMobileTwo: state => {
            return state.mobileTwo;
        },
        getMail: state => {
            return state.mail;
        },
        getPlace: state => {
            return state.place;
        },
        getStreet: state => {
            return state.street;
        },
        getEntrance: state => {
            return state.entrance;
        },
        getAddress: state => {
            return state.address;
        },
        getOpen: state => {
            return state.open;
        },
        getOpenWeekend: state => {
            return state.openWeekend;
        },
        getFacebookUrl: state => {
            return state.facebookUrl;
        },
        getGoogleMapUrl: state => {
            return state.googleMapUrl;
        }
    },
    mutations: {
        setPolishLanguage: state => {
            state.language = 1;
        },
        setEnglishLanguage: state => {
            state.language = 0;
        }
    },
    actions: {
        setPolishLanguage: ({ commit }) => {
            commit('setPolishLanguage')
        },
        setEnglishLanguage: ({ commit }) => {
            commit('setEnglishLanguage')
        },
    }
});
