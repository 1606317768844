<template>
  <ul class="list-style-none">
    <li>{{this.getStreet()}}</li>
    <li>{{this.getPlace()}}</li>
    <li>{{this.getEntrance()}}</li>
    <li>{{this.getAddress()}}</li>
  </ul>
</template>
<script>
import { mapGetters } from "vuex";
export default {
    methods:{
        ...mapGetters(["getPlace","getStreet","getEntrance","getAddress"])
    }
}
</script>