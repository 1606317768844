<template>
  <section class="documents">
    <h1>{{this.getTitle()}}</h1>
    <p>{{this.getText()}}</p>
    <ul>
      <li>{{this.getSell()}}</li>
      <li>{{this.getGive()}}</li>
      <li>{{this.getQuit()}}</li>
    </ul>
    <p>{{this.getInfo()}}</p>
    <div class="documents-to-download">
      <Document :fileName="this.getSellFile()" filePath="umowa_kupna_sprzedazy_samochodu.pdf" />
      <Document :fileName="this.getGiveFile()" filePath="umowa_darowizny_pojazdu.pdf" />
      <Document :fileName="this.getQuitFile()" filePath="wypowiedzenie_oc_komunikacyjne.pdf" />
    </div>
  </section>
</template>
<script>
import Document from "./Document";
import { translation } from "./translation";
export default {
  components: {
    Document
  },
  mixins: [translation]
};
</script>
<style>
.documents-to-download {
  column-gap: 40px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding-top: 32px;
}
@media only screen and (max-width: 769px) {
  .documents-to-download {
    padding-top: 16px;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, 1fr);
    row-gap: 16px;
  }
}
</style>