export const translation = {
    data() {
        return {
            offer: ["Insurance offers", "Oferujemy ubezpieczenia"],
            vehicle: ["Vehicle", "Komunikacyjne"],
            health: ["Health", "Zdrowotne"],
            property: ["Property", "Mienia"],
            business: ["Business", "Firmowe"],
            travel: ["Travel", "Turystyczne"],
            agro: ["Agricultural", "Rolne"],
            life: ["Life", "Życie"],
            group: ["Group", "Grupowe"],
            school: ["School", "Szkolne"],
            promoTitle: ["Insurance tailored to your needs", "Ubezpieczenie dopasowane do Twoich potrzeb"],
            promoSubtitle: ["Partnership with many insurance companies and our many years of experience allows us to present you the most advantageous offer.", "Współpraca z wieloma towarzystwami ubezpieczeniowymi oraz nasze wieloletnie doświadczenie pozwala nam przedstawić Państwu najkorzystniejszą ofertę."],
            remote: ["#stayathome", "#zostanwdomu"],
            remoteSell: ["We offer insurance sales without leaving home! Just:", "Oferujemy sprzedaż ubezpieczeń bez wychodzenia z domu! Wystarczy:"],
            stepOne: ["You contact us by phone or email.", "Państwo kontaktują się z nami telefonicznie lub mailowo."],
            stepTwo: ["We present an offer.", "My przedstawiamy ofertę."],
            stepThree: ["You pay for the selected policy by bank transfer.", "Wybraną polisę Państwo opłacają przelewem."],
            stepFour: ["It's so simple!", "To takie proste!"],
            officeLocation: ["Office localization:","Znajdujemy się w:"],
            workHours: ["Working hours:","Jestesmy otwarci:"],
            contact: ["Contact us:","Kontakt:"],
            recommend: ["RECOMMEND INSURANCE","POLECAMY UBEZPIECZENIA"],
            recommendTravel: ["Are you going on vacation? Take out insurance for the duration of your trip.","Wybierasz się na urlop? Ubezpiecz się na czas podróży."],
            recommendSchool: ["Accident Insurance for your child in school and outside of it.","NNW dla twojego dziecka w szkole i poza nią."],
            invitation: ["We invite you to our office or call to us.","Zapraszamy do naszego biura lub kontaktu telefonicznego."],
            useReferenceLinks: ["If you don't want to leave your home use the links above!","Możesz również ubezpieczyć się bez wychodzenia z domu, korzystając z powyższych linków!"]
        }
    },
    methods: {
        getOffer() {
            return this.offer[this.$store.state.language];
        },
        getVehicle() {
            return this.vehicle[this.$store.state.language];
        },
        getHealth() {
            return this.health[this.$store.state.language];
        },
        getProperty() {
            return this.property[this.$store.state.language];
        },
        getBusiness() {
            return this.business[this.$store.state.language];
        },
        getTravel() {
            return this.travel[this.$store.state.language];
        },
        getAgro() {
            return this.agro[this.$store.state.language];
        },
        getLife() {
            return this.life[this.$store.state.language];
        },
        getGroup() {
            return this.group[this.$store.state.language];
        },
        getSchool(){
            return this.school[this.$store.state.language];
        },
        getPromoTitle() {
            return this.promoTitle[this.$store.state.language];
        },
        getPromoSubtitle() {
            return this.promoSubtitle[this.$store.state.language];
        },
        getRemote() {
            return this.remote[this.$store.state.language];
        },
        getRemoteSell() {
            return this.remoteSell[this.$store.state.language];
        },
        getStepOne() {
            return this.stepOne[this.$store.state.language];
        },
        getStepTwo() {
            return this.stepTwo[this.$store.state.language];
        },
        getStepThree() {
            return this.stepThree[this.$store.state.language];
        },
        getStepFour() {
            return this.stepFour[this.$store.state.language];
        },
        getOfficeLocation() {
            return this.officeLocation[this.$store.state.language];
        },
        getWorkHours() {
            return this.workHours[this.$store.state.language];
        },
        getContact() {
            return this.contact[this.$store.state.language];
        },
        getRecommend(){
            return this.recommend[this.$store.state.language];
        },
        getRecommendTravel(){
            return this.recommendTravel[this.$store.state.language];
        },
        getRecommendSchool(){
            return this.recommendSchool[this.$store.state.language];
        },
        getInvitation(){
            return this.invitation[this.$store.state.language];
        },
        getUseReferenceLinks(){
            return this.useReferenceLinks[this.$store.state.language];
        }
    }
}