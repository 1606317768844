<template>
  <header class="mobile-header">
    <Logo />
    <button class="mobile-header-navigation-btn" @click="navigationHandler()" title="Menu">
      <font-awesome-icon icon="bars" />
    </button>
    <div id="body-shadow" @click="navigationHandler()"></div>
    <div id="mobile-navigation-drawer">
      <Icon />
      <Navigation />
    </div>
  </header>
</template>
<script>
import Logo from "./MobileLogo";
import Icon from "./MobileIcon";
import Navigation from "./MobileNavigation";
export default {
  data() {
    return {
      navigation: false,
      navigationElement: "",
      shadowElement: ""
    };
  },
  components: {
    Logo,
    Icon,
    Navigation
  },
  methods: {
    navigationHandler() {
      if (!this.navigation) this.showNavigation();
      else this.hiddeNavigation();
      this.navigation = !this.navigation;
    },
    showNavigation() {
      var bodyWidth = document.body.clientWidth;
      var bodyHeight = document.body.clientHeight;
      this.shadowElement.style.width = bodyWidth + 16 + "px";
      this.shadowElement.style.height = bodyHeight + 32 + "px";
      this.shadowElement.style.zIndex = 998;
      this.shadowElement.style.opacity = 0.3;
      this.navigationElement.style.height = bodyHeight + "px";
      this.navigationElement.style.left = "0px";
    },
    hiddeNavigation() {
      this.shadowElement.style.zIndex = -1;
      this.shadowElement.style.opacity = 0;
      this.navigationElement.style.left = "-288px";
    }
  },
  mounted() {
    this.navigationElement = document.getElementById(
      "mobile-navigation-drawer"
    );
    this.shadowElement = document.getElementById("body-shadow");
  }
};
</script>
<style>
.mobile-header {
  display: grid;
  grid-area: 1/1/1/4;
  grid-template-columns: auto 64px;
  grid-template-rows: 64px;
  padding: 16px 16px 0px 16px;
}
.mobile-header-navigation-btn {
  background-color: #ffffff;
  border-radius: 32px;
  border: none;
  color: #282727;
  font-size: 36px;
  line-height: 60px;
  text-align: center;
}
.mobile-header-navigation-btn:hover {
  background-color: #eaf1f8;
}
#body-shadow {
  background-color: #000000;
  opacity: 0;
  position: absolute;
  overflow: hidden;
  height: 100vh;
  width: 100vw;
  top: 0px;
  left: 0px;
  z-index: -1;
}
#mobile-navigation-drawer {
  background-color: #ffffff;
  display: grid;
  grid-template-rows: 128px auto;
  height: 100vh;
  min-height: max-content;
  width: 256px;
  padding: 0px 16px;
  position: absolute;
  top: 0px;
  left: -288px;
  z-index: 999;
  transition-duration: 1s;
}
@media only screen and (min-width: 769px) {
  .mobile-header {
    display: none;
  }
}
</style>