export const translation = {
    data() {
        return {
            insuranceTitle: ["Insurances", "Ubezpieczenia"],
            contactTitle: ["Contact", "Kontakt"],
            workingTitle: ["Work hours", "Zapraszamy w godzinach"],
            navigationTitle: ["Navigation", "Nawigacja"],
            monFri: ["Mon-Fri: ", "Pon-Pt: "],
            sat: ["Sat: ", "Sob: "],
            localization: ["Localization", "Lokalizacja"]
        }
    },
    methods: {
        getInsuranceTitle() {
            return this.insuranceTitle[this.$store.state.language];
        },
        getContactTitle() {
            return this.contactTitle[this.$store.state.language];
        },
        getWorkingTitle() {
            return this.workingTitle[this.$store.state.language];
        },
        getNavigationTitle() {
            return this.navigationTitle[this.$store.state.language];
        },
        getMonFri() {
            return this.monFri[this.$store.state.language];
        },
        getSat() {
            return this.sat[this.$store.state.language];
        },
        getLocalization() {
            return this.localization[this.$store.state.language];
        }
    }
}